









































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsJobCard from '@/modules/dashboard/modules/jobs/components/job-card.vue';
import {Action, Getter} from 'vuex-class';
import {JobsActions} from '@/modules/dashboard/modules/jobs/store/actions';
import JobsModule from '@/modules/dashboard/modules/jobs';
import DashboardModule from '@/modules/dashboard';
import {IApiJob} from '@/shared/models/IApiJob';
import {JobsGetters} from '@/modules/dashboard/modules/jobs/store/getters';
import RsLoading from '@/shared/components/loading/loading.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import {ProfileRouter} from '@/modules/dashboard/modules/profile/router';
import {PROFILE_COMPLETION_SERVICE} from '@/modules/dashboard/modules/profile/services/profile-completion.service';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {API_SERVICE} from '@/shared/api/api.service';
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import {Watch} from 'vue-property-decorator';
import {DATE_SERVICE} from '@/shared/services/date.service';

@Component({
  components: {
    RsCheckbox,
    RsSelect, RsCheckboxCard, RsLinkIcon, RsButton, RsLoading, RsJobCard, RsIcon
  }
})
export default class JobsIndex extends Vue {
  @Action(JobsActions.GET_JOBS, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) getJobs: () => Promise<void>;
  @Action(JobsActions.LOAD_MORE, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) loadMore: () => Promise<void>;
  @Action(JobsActions.SET_FILTERS, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) setFilters: (filters: { roles: string[], timezone: string }) => Promise<void>;

  @Getter(JobsGetters.GET_JOBS, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) jobs: IApiJob[];
  @Getter(JobsGetters.GET_TOTAL, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) total: number;
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  ProfileRouter = ProfileRouter

  loading = false;

  loadingMore = false;


  filters: {
    roles: string[],
    timezone: string
  } = {
    roles: [],
    timezone: ''
  }

  timezoneCheckbox = false;

  codetables: CodetableResult = {}

  @Watch('timezoneCheckbox')
  onCheckboxChange(checked: boolean) {
    if(checked){
      this.setFilters(this.filters)
    }
    else {
      this.setFilters({
        roles: this.filters.roles,
        timezone: ''
      })
    }
  }

  @Watch('filters.timezone')
  onTimezoneChange() {
    if(this.timezoneCheckbox){
      this.setFilters(this.filters)
    }
  }

  @Watch('filters.roles')
  onRoleChange(roles: string[]) {
    if(this.timezoneCheckbox){
      this.setFilters({
        ...this.filters,
        roles: roles,
      })
    }
    else {
      this.setFilters({
        roles: roles,
        timezone: ''
      })
    }
  }

  load() {
    this.loadingMore = true;
    this.loadMore()
      .finally(() => {
        this.loadingMore = false;
      })
  }

  get profileCompleted(): boolean {
    return PROFILE_COMPLETION_SERVICE.personalInfoCompletion(this.user)
      && PROFILE_COMPLETION_SERVICE.skillsCompletion(this.user)
      && PROFILE_COMPLETION_SERVICE.workCompletion(this.user)
      && PROFILE_COMPLETION_SERVICE.referencesCompletion(this.user);
  }

  formatTime(code: string){
    const utcOffset = parseFloat(code.replace('.3', '.5').split('UTC')[1]);
    return DATE_SERVICE.instance().utc().add(utcOffset, 'hours').format('hh:mma')
  }

  mounted() {
    this.filters.timezone = this.user.properties.TALENT_WORK_TIMEZONE || '';
    this.$track.sendEvent('Jobs', {isPublic: false})
    if (this.jobs.length <= 0) {
      this.loading = true;
    }
    this.getJobs()
      .finally(() => {
        this.loading = false;
      })
    API_SERVICE.codetable.getCodetables(
      Codetables.DEVELOPER_ROLES,
      Codetables.TIMEZONES,
    ).then((codetables) => {
      this.codetables = codetables
    })
  }
}
